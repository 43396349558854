import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class LeagueHistoryService {

  constructor(private httpClient: HttpClient) { }

  /**
  * Returns all league data: general, settings, results, etc.
  * @param leagueId - pass in the league id to return all league information
  */
  getPublicLeagueCurrent(leagueId: string) {
    const currentYear: number = new Date().getFullYear();
    let url: string = `https://lm-api-reads.fantasy.espn.com/apis/v3/games/ffl/seasons/${currentYear}/segments/0/leagues/${leagueId}?view=mMatchupScore&view=mTeam&view=mSettings`;
    return this.httpClient.get(url);
  }

  /**
  * Returns all league data: general, settings, results, etc.
  * @param leagueId - pass in the league id to return all league information
  */
  getPublicLeagueHistoric(leagueId: string) {
    let url: string = `https://lm-api-reads.fantasy.espn.com/apis/v3/games/ffl/leagueHistory/${leagueId}?view=mLiveScoring&view=mMatchupScore&view=mRoster&view=mSettings&view=mStandings&view=mStatus&view=mTeam&view=modular&view=mNav&seasonId=`;
    return this.httpClient.get(url);
  }

  /**
  * Returns all league data: general, settings, results, etc.
  * @param leagueId - pass in the league id to return all league information
  * @param swid - pass in the private leagues swid cookie
  * @param espn_s2 - pass in the private league espn_s2 cookie
  */
  getPrivateLeagueCurrent(leagueId: string, swid: string, espn_s2: string) {
    const url: string = 'http://173.255.238.116:5000/current'
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { leagueId, swid, espn_s2 };
    const res = this.httpClient.post(url, body, {headers});
    return res;
  }

  /**
  * Returns all league data: general, settings, results, etc.
  * @param leagueId - pass in the league id to return all league information
  * @param swid - pass in the private leagues swid cookie
  * @param espn_s2 - pass in the private league espn_s2 cookie
  */
  getPrivateLeagueHistoric(leagueId: string, swid: string, espn_s2: string) {
    const url: string = 'http://173.255.238.116:5000/historic'
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { leagueId, swid, espn_s2 };
    const res = this.httpClient.post(url, body, {headers});
    return res;
  }

}
